import React, {useEffect} from 'react';
import {useAuth} from '../services/auth';
import {useHasMounted} from '../hooks/use-has-mounted';
import {Redirect} from '@reach/router';

const Logout = () => {
  const hasMounted = useHasMounted();
  const {logout} = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return hasMounted ? <Redirect to="/" noThrow /> : null;
};

export default Logout;
